<template>
  <div id="inspire">
    <div>
      <div fluid class=" flex flex-col justify-center items-center p-0 h-screen px-4">
        <div class="bg-staticCol card card_design w-full px-6 sm:px-12 py-4 sm:m-0">
          <div class="text-center flex items-center justify-center">
            <img class="w-64" src="@/assets/logo.png" />
            <!-- <i class="fa-solid fa-envelope-open-text text-primary heading-2"></i>
            <div class="heading-2 text-primary font-semibold px-4">Text Campaign</div>
            <i class="fa-solid fa-envelope-open-text text-primary heading-2"></i> -->
           </div>
        </div>
        <div class="bg-white card  card_design w-full sm:m-0 " style="margin-top: 20px !important;">
          <div class="text-center flex items-center justify-center bg-primary h-16">
            <div class="heading-2 text-white font-semibold pl-1">Login to Text Campaign</div>
          </div>
          <div class="flex-wrap px-6 sm:px-12 py-4 ">
            <div
              class="text-left">
              <div class="mb-3 p-0 ">
                <div>
                  <div class="mb-4">
                     <TextField
                      :inputId="`useremailid`"
                      :inputext="userEmail"
                      :placholderText="`Email`"
                      :lableText="'Email'"
                      :fieldError="emailErr !== ''"
                      :autoFocus="true"
                      :inputType="'text'"
                      @keyPressAction="emailErr = ''"
                      @enterkeyPressAction="loginActionCall"
                      @blurAction="emailHandler(userEmail, '')"
                      @inputChangeAction="(data) => userEmail = data"  />
                      
                      <p class="text-error"> {{ emailErr }}</p>
                  </div>
                  <div class="mb-4">
                     <TextField
                      :inputId="`userpassid`"
                      :inputext="userPass"
                      :placholderText="`Password`"
                      :lableText="'Password'"
                      :fieldError="passErr !== ''"
                      :autoFocus="false"
                      :inputType="'password'"
                      @enterkeyPressAction="loginActionCall"
                      @keyPressAction="passErr = ''"
                      @inputChangeAction="(data) => userPass = data"  />
                      <p class="text-error"> {{ passErr }}</p>
                  </div>
                  <span @click="forgotDialog = true" class="text-primary cursor-pointer heading-6 text-right  float-right py-2 pt-0">Forgot Password?</span>
                  <div class="py-2">
                    <Button  class=" h-10 w-full" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Log In'" @buttonAction="loginActionCall()"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="forgotDialog" class="popup_overlay px-4">
          <div class="custom_dialog rounded-lg" style="width: 500px;max-height: 95%;">
            <div class="relative bg-white">
              <div>
                <div class="flex p-2 m-2 text-error rounded-lg  items-center" role="alert">
                  <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                  <div>
                    <p class=" font-medium heading-4">Please contact Admin to reset your login password.</p>
                  </div>
                </div>
                <div class="py-2 pt-1 text-center">
                  <Button  class=" h-10" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Ok'" @buttonAction="forgotDialog=false"/>
                </div>
              </div>
            </div>
          </div>
      </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
  </div>
</template>
<script>
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
import TextField from '@/View/components/textfield.vue'
import Button from '@/View/components/globalButton.vue'
// import MyJobApp from '@/api/MyJobApp.js'
import {getEncryptedPassword} from '@/utils/encrytPassword'
import MyJobApp from '@/api/App.js'
import {validateEmail} from '@/utils/validation.js'
import {setCookiesWithExpire} from '@/utils/cookies.js'
export default {
  components: {
    Loader,
    snakBar,
    TextField,
    Button,
  },
  data () {
    return {
      forgotDialog: false,
      userEmail: '',
      userPass: '',
      emailErr: '',
      passErr: '',
      emailValid: false,
      forgotEmailErr: '',
    }
  },
  beforeMount () {},
  created () {},
  mounted () {},
  watch: {
  },
  methods: {
    loginActionCall () {
      let isvalid = true

      if (this.userEmail === "" && this.userPass === "") {
        this.emailErr = "Email is required"
        this.passErr = "Password is required"
        isvalid = false
      }
      if (this.userPass === "") {
        this.passErr = "Password is required"
        isvalid = false
      }
      if (!this.emailValid) {
        isvalid = false
      }
      if (isvalid) {
        let encPassword = getEncryptedPassword(this.userPass)
        MyJobApp.loginToSpecificCompany(
          this.userEmail,
          encPassword,
          response => {
            localStorage.removeItem('resendOtpAttemptsCount')
            localStorage.removeItem('verifyOtpAttemptsCount')
            localStorage.removeItem('emailAddress')
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            localStorage.removeItem('companyData')
            localStorage.setItem('employeeData1', JSON.stringify(response.Data))
            setCookiesWithExpire('authToken', response.Data.authToken, 30)
            let routeTo = localStorage.getItem('routeNextToA')
            if (routeTo !== '' && routeTo !== undefined && routeTo !== null) {
              if (routeTo !== 'StaffListing' && routeTo !== '/staff') {
                localStorage.removeItem('routeNextToA')
                this.$router.push({path: routeTo})
              } else {
                localStorage.removeItem('routeNextToA')
                this.$router.push({name: 'dashboard'})
              }
            } else {
              this.$router.push({name: 'dashboard'})
            }
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
      }
    },
    emailHandler (emailId, from) {
      if (from === '') {
        this.emailErr = this.emailValidation(emailId)
        if (this.emailErr === 'Email is invalid') {
          this.emailValid = false
        } else {
          this.emailValid = true
        }
      } else if (from === 'forgot') {
        this.forgotEmailErr = this.emailValidation(emailId)
      }
    },
     emailValidation (emailId) {
      if (emailId === '') {
        return ''
      } else if (validateEmail(emailId) === false) {
        return 'Email address is invalid'
      } else {
        return ''
      }
    },
  },
  beforeDestroy () {},
  computed: {
  }
}
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Saira+Stencil+One&display=swap");
.backgroundWindow {
  background: url('https://workapp-public-download.s3.ap-south-1.amazonaws.com/assets/signup-in-background.jpg');
  background-size: cover;
  background-position: center
}
.card_design {
  max-width: 500px;
}

</style>
